import { FOOTNOTE_ID_PREFIX } from '@vfde-brix/footnotes';
import { CONTENT_ID } from '../constants';

/**
 * Initialize JumpLink scrolling
 */
export const initJumpLinkScrolling = () => {
    const contentElement = document.getElementById(CONTENT_ID);

    /* istanbul ignore if */
    if (!contentElement) {
        return;
    }

    contentElement.addEventListener('click', e => {
        const target = e.target as HTMLAnchorElement;
        const closestJumpLink = target.closest(`a[href^="#"]:not([href^="#${FOOTNOTE_ID_PREFIX}"])`);

        if (!closestJumpLink) {
            // click was not on a jump-link
            return;
        }

        const targetId = closestJumpLink.getAttribute('href')!.trim().slice(1);

        if (!targetId) {
            return;
        }

        const targetElement = document.getElementById(targetId);

        if (!targetElement) {
            return;
        }

        e.preventDefault();

        // focus element without scrolling
        targetElement.focus({ preventScroll: true });

        // scroll element smoothly into view
        targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    });
};
